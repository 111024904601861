<script>
import { mapActions, mapMutations, mapGetters } from "vuex";

import Footer from "@/layouts/Footer.vue";
import NavBarVotacion from "@/components/NavBarVotacion.vue";
import Modal from "@/components/Modal.vue";
import ReactionButton from "@/components/ReactionButton.vue";
import {reactNotification} from '../../api/notifications/get'
import SimpleLoading from "@/components/SimpleLoading.vue";

export default {
	props: {
		isTour: {
			required: false,
			type: Boolean,
		},
	},
	components: {
		Footer,
		NavBarVotacion,
		Modal,
		ReactionButton,
		SimpleLoading
	},
	data() {
		return {
			size: 5,
			isScrolling: false,
			messages: {
				vote: [],
				reaction: []
			},
			modal: {
				title: null,
				agency: null,
				work_position: null,
				message: null,
				comment: null,
				show: false,
				showComment: false,
				gone: false,
				type: '',
				reaction: null,
			},
			modalReaction: {
				show: false,
				gone: false,
			},
			selectedReaction: {},
			selectedNotification: {},
			selectedStatus: 'all',
			selectedType: 'vote',
			featching: null,
			comment: ''
		};
	},
	async created() {
		await this.getReactions();
		await this.loadMessages(true, {type: 'vote', page: 1, size: this.size});
		await this.loadMessages(true, {type: 'reaction', page: 1, size: this.size})
		let vm = this
	},

	beforeDestroy() {
		clearInterval(this.featching)
	},
	computed: {
		votes() {
			return 0
		},
		reactions() {
			return 0
		},
		showMessage() {
			return this.readNotifications(this.selectedType)
		},
		...mapGetters({
			isLoading: "loading/isLoading",
			//EMITTERS
			readEmitter: "getUsers/readUsers",
			//NOTIFICATIONS
			readNotifications: "getNotifications/readNotifications",
			readCountVote: "getNotifications/readCountVote",
			readCountReaction: "getNotifications/readCountReaction",
			//ERRORS
			readNotificationsError: "getNotifications/readError",
			readReactions: "getReactions/readReactions",
			readHasReadError: "hasReadNotification/readError",
			readToken: 'loginStore/readToken',
			readUser:  'auth/readUser'
		}),
	},
	methods: {
		...mapMutations({
			setLoading: "loading/SET_LOADING",
			//EMITTERS
			getEmitterId: "getUsers/SET_ID",
			setEmitterPagination: "getUsers/SET_PAGINATION",
			//NOTIFICATIONS
			setHasReadId: "hasReadNotification/SET_ID",
			setNotificationsId: "getNotifications/SET_ID",

			//ERRORS
			setNotificationsError: "getNotifications/SET_ERROR",
			setHasReadError: "hasReadNotification/SET_ERROR",
		}),
		...mapActions({
			//EMITTERS
			getEmitter: "getUsers/getUsers",
			//NOTIFICATIONS
			setHasRead: "hasReadNotification/hasRead",
			getNotifications: "getNotifications/getNotifications",
			getReactions: 'getReactions/getReactions'
		}),
		showModal(modal = "modal") {
			this[modal].gone = false;
			this[modal].show = true;
		},
		hideModal(modal = "modal") {
			this[modal].gone = true;
			this[modal].show = false;
		},
		showComment() {
			if (this.modal.comment) this.modal.showComment = true;
		},
		hideComment() {
			this.modal.showComment = false;
		},
		getImage(image) {
			return `${require(`@/assets/images/views${this.$route.path.toUpperCase()}/${image}`)}`;
		},
		changeStatus() {
			this.loadMessages(true, {type:  this.selectedType, page: 1, status: this.selectedStatus, size: this.size})
		},	
		selectMessage(message) {
			this.modal.title = message?.title;
			this.modal.work_position = message?.emitter?.work_position;
			this.modal.agency = message?.agency;
			this.modal.message = message?.content;
			this.modal.comment = message?.comment;
			this.modal.type = message?.type;
			this.modal.reaction = this.readReactions.find(r => r.id == message.reaction_id);
			this.showModal();
			
			if(message.type == 'reaction' && message.emitter_id == this.readUser.id) {
				this.modal.title = 'Tu reaccionaste'
				if(message.comment) {
					this.modal.message = ''
				}
				
			}
			if(message.emitter_id != this.readUser.id) {
				if (!this.isTour) {
					if (message?.is_unread) this.markRead(message?.id);
				}
			}
		},
		markRead(id) {
			
			this.cleanErrors();
			this.setHasReadId(id);
			this.setHasRead().finally(() => {
				let message = this.showMessage.items.find(i => i.id == id)
				message.is_unread = 0
				// this.loadMessages(false, {type: this.selectedType });
			});
		},
		async loadMessages(loading = true, params = {type: 'vote', page: 1, status: 'all', size: 5}) {
			this.setLoading(loading);
			this.cleanErrors();
			this.setNotificationsId(null);
			console.log(params)
			return this.getNotifications(params)
				.then((result) => {
					if (result) {
						// this.loadEmitters(this.readNotifications);
						this.$set(this.messages, params.type, this.readNotifications(params.type))
					} else {
						this.messages[params.type] = [];
					}
				})
				.finally(() => {
					this.setLoading(false);
				});
		},
		loadEmitters(payload) {
			payload.filter((m, key) => {
				this.$set(this.messages, key, m);
				this.setEmitterPagination(null);
				this.getEmitterId(m.emitter_id);
				this.getEmitter().then((response) => {
					if (response) {
						this.$set(
							this.messages[key],
							"photo",
							this.readEmitter[0].photo || null
						);
						this.$set(
							this.messages[key],
							"work_position",
							this.readEmitter[0].work_position || null
						);
						this.$set(
							this.messages[key],
							"agency",
							this.readEmitter[0].agency || null
						);
					}
				});
			});
		},
		cleanErrors() {
			this.setHasReadError(null);
			this.setNotificationsError(null);
		},
		getReactionImage(image) {
			return `${require(`@/assets/images/components/reactions/${image}`)}`;
		},

		emitReaction(reaction, notification) {

			this.selectedReaction = reaction
			this.selectedNotification = notification
			this.showModal('modalReaction')
			
		},

		async submitReaction() {
			this.setLoading(true);
			this.hideModal('modalReaction')
			this.selectedNotification.reaction_id = this.selectedReaction.id
			try {
				let result = await reactNotification(this.readToken, {parent_id: this.selectedNotification.id, reaction_id: this.selectedReaction.id, comment: this.comment})
				
			} catch (error) {
			}
			this.selectedNotification = {}
			this.selectedReaction = {}
			this.comment = ''
			this.setLoading(false);
			this.loadMessages( true, {type: 'vote', status: this.selectedStatus, size: this.size})
		},
		async setTypeNotification(type) {
			this.selectedType = type
			this.selectedStatus = 'all'
			await this.loadMessages(true, {type, page: 1, size: this.size, status: this.selectedStatus});
		},
		showMyReaction (m) {
			if(!m.reaction_id) {
				return
			}

			this.selectMessage(m.children[0])
		},
		async scroll() {
			if (
				!this.isScrolling && 
				this.showMessage.page < (this.showMessage.total/this.showMessage.size)
			) {
				const listNotifications = this.$refs.listNotifications;

				if (
					listNotifications?.scrollTop >
					(listNotifications?.scrollHeight - listNotifications?.offsetHeight) * 0.9
				) {
					this.isScrolling = true;
					await this.loadMessages(false, {type: this.selectedType, status: this.selectedStatus, page: this.showMessage.page + 1, size: 5});
					this.isScrolling = false;
				}
			}
		},
		
	},
};
</script>
<template>
	<div class="relative h-full flex flex-col justify-center items-center">
		<div class="modal-wrap" v-if="modal.show || modal.showComment">
			<Modal
				buttonText="Cerrar"
				@click.native="hideModal()"
				class="modal-base"
				:class="{ 'modal-active': modal.show, 'modal-out': modal.gone }"
			>
				<div class="text-black text-center">
					<span class="b  block text-xl">{{
						modal.title
					}} </span>
					<span
						class="block text-xl"
						v-capitalizeWords="modal.work_position"
					></span>
					<span
						class="block text-xl"
						v-capitalizeWords="modal.agency"
					></span>
					<hr class="bg-gray h-0.5 my-2 w-full block" />
				
					<span class="block text-lg py-1">{{ modal.message }}</span>
					<span class="block text-lg p-2 elektra-red">{{modal.comment}}</span>
					<div v-if="modal.type == 'reaction'" class="flex">
						<img :src="getReactionImage(modal.reaction.image)" alt="" class="mx-auto w-20 animtae-smile">
					</div>
				</div>
			</Modal>
			
			
		</div>
		<div class="modal-wrap" v-if="modalReaction.show">
			<Modal
				buttonText="Reaccionar"
				@click="submitReaction"
				class="modal-base relative"
				:class="{ 'modal-active': modalReaction.show, 'modal-out': modalReaction.gone }"
			>
			
			
				<div class="text-black text-center relative">
					<font-awesome-icon
					@click="hideModal('modalReaction')"
					icon="fa-solid fa-xmark"
					class="absolute right-0 top-0 rounded-full border  w-5 h-5 z-10	"
					/>
					<div v-if="selectedReaction" class="flex">
						<!-- <img :src="getReactionImage(selectedReaction.image)" alt="" class="mx-auto w-20 animtae-smile"> -->
					</div>
					<div class="text-black text-lg b text-center mt-5"
						>¿Deseas agregar algún comentario?</div
					>
					<span class="">(opcional)</span>
					<textarea
						class="w-full flex-grow text-lg black h-48 mt-2 pl-4 pr-8 py-2 text-lg rounded-md bg-white"
						placeholder="Comentarios adicionales"
						v-model="comment"
						maxlength="140"
					/>
					<span class=" mt-2 mb-4"
					><span class="elektra-red">{{ 140 - comment.length }} </span
						>caracteres disponibles</span>
				</div>
			</Modal>
		</div>
		<div class="my-2 flex flex-col overlay bg-white mx-auto">
			<div class="flex mb-6">
				<button class="w-full text-center font-bold tab  pl-3" :class="{'tab--active': selectedType == 'vote'}" @click="setTypeNotification('vote')">
					Reconocimientos
					<span class="tab-indicator text-white" v-if="readCountVote">{{readCountVote}}</span>
				</button>
				<button class="w-full text-center font-bold tab" :class="{'tab--active': selectedType == 'reaction'}"  @click="setTypeNotification('reaction')">
					Reacciones
					<span class="tab-indicator text-white" v-if="readCountReaction">{{readCountReaction}}</span>
				</button>
			</div>
			<div
				class="bg-contain bg-top bg-no-repeat mx-5 pt-0 mt-1"
				
			>
				
				<p class="b bg-overlay text-center text-xl leading-5 mb-3">
					<span class="elektra-red block">¡Felicidades!</span>
				</p>
				<p class="bg-overlay text-lg text-center" v-if="selectedType == 'vote'">
					Tus compañeros te reconocen como un
					<span class="elektra-red b">Creador de </span>
					<span class="elektra-red b">momentos </span>
					<span class="elektra-red b">felices</span>.
					<span class="block">
						Haz <span class="b elektra-red">clic</span> en los nombres para revisar los reconocimientos.
					</span>
				</p>
				<p class="bg-overlay text-lg text-center" v-else-if="selectedType == 'reaction'">
					Tus compañeros han reaccionado a los reconocimientos que han recibido. 
					<span class="block">
						Haz <span class="b elektra-red">clic</span>  en los nombres para revisar sus reacciones.
					</span>
				</p>
			</div>
			<div class="flex">
				<label class="app-label-tag" :class="{'app-label-tag--active': selectedStatus == 'all'}">
					<input type="radio" name="status" value="all" v-model="selectedStatus" @change="changeStatus">	
					Todos
				</label>
				<label class="app-label-tag" :class="{'app-label-tag--active': selectedStatus == 'unread'}">
					<input type="radio" name="status" value="unread" v-model="selectedStatus" @change="changeStatus">	
					No vistos
				</label>
				<label class="app-label-tag" :class="{'app-label-tag--active': selectedStatus == 'read'}">
					<input type="radio" name="status" value="read" v-model="selectedStatus" @change="changeStatus">	
					Vistos
				</label>
			</div>
			<div
				ref="listNotifications"
				:key="`noti_${selectedType}`"
				v-if="!isLoading && showMessage.items.length > 0"
				@scroll="scroll"
				class="messages-list h-4/6 overflow-y-scroll pt-3"
			>
				<div
					
					v-for="(m, key) in showMessage.items"
					:id="`notification_${key}`"
					:key="m.id"
					class=""
					
				>
					<div v-if="m.type == 'vote'" @click="selectMessage(m)" class="message mb-3 flex justify-between items-center p-2 rounded-xl mx-5 overflow-visible cursor-pointer" :class="
						m.is_unread
							? 'item item--unread cursor-pointer gradient-red'
							: 'item'
						">
						<span
							class="flex flex-none justify-center items-center icon_user rounded-full overflow-hidden"
						>
							
							<img
								class="block w-full h-full object-cover object-center flex-none transform "
								:src="m.emitter.photo || 'profile.png'"
								alt="Trofeo"
							/>
						</span>
						<div class="label w-5/6 pl-2 flex-grow">
							<div class="pb-2">
							<span class="block text-sm">{{
								m.created_at | moment("DD-MM-YYYY")
							}}</span>
							<span class="block b text-md truncate">{{
								m.title
							}}</span>
							
							<span class="block text-md truncate">{{
								m.content
							}}</span>
							</div>
							<ReactionButton :selected="m.reaction_id || 0" :reactions="readReactions" @change="(payload) => emitReaction(payload, m)" @click="showMyReaction(m)" @click.native.stop=""/>
						</div>
					</div>

					<div v-else-if="m.type == 'reaction'" class="mb-3" >
					 	<div  @click="selectMessage(m.parent)" class="message radius-br-0  radius-bl-0 flex justify-between item items-center p-2 rounded-xl mx-5 overflow-visible cursor-pointer">
							<span
								class="flex flex-none justify-center items-center icon_user rounded-full overflow-hidden"
							>
								<font-awesome-icon
									v-if="!readUser.photo"
									icon="fa-solid fa-user  text-lg"
								/>
								<img
									v-else
									class="block w-full h-full object-cover object-center flex-none transform rotate-90"
									:src="readUser.photo"
									alt="Trofeo"
								/>
							</span>

							<div class="label w-5/6 pl-2 flex-grow">
								<div class="pb-2">
		
								<span class="block b text-md truncate">Tu reconociste</span>
								<span class="block text-sm">{{
									m.parent.created_at | moment("DD-MM-YYYY")
								}}</span>
								<span class="block text-md truncate">{{
									m.parent.content
								}}</span>
								
								</div>
								<!-- <span>{{ m.emitter.name }} dice </span><ReactionButton :selected="m.reaction_id || 0" :reactions="readReactions" @change="(payload) => emitReaction(payload, m)"/> -->
							</div>

						</div>
						<div class="pl-0">
							<div @click="selectMessage(m)" class="message radius-tx-0   flex justify-between items-center p-2 rounded-xl mx-5 overflow-visible cursor-pointer" :class="
								m.is_unread
									? 'item item--unread cursor-pointer gradient-red'
									: 'item bg-gray'
								">
								<span
									class="flex flex-none justify-center items-center icon_user rounded-full overflow-hidden"
								>
									
									<img
										class="block w-full h-full object-cover object-center flex-none transform "
										:src="m.emitter.photo || 'profile.png'"
										alt="Trofeo"
									/>
								</span>
								<div class="label w-5/6 pl-2 flex-grow">
									<div class="pb-0">
										<span class="block b text-md truncate">{{
											m.title
										}}</span>
										<span class="block text-sm">{{
											m.created_at | moment("DD-MM-YYYY")
										}}</span>
									</div>
									<span class="block text-md truncate">{{
										m.comment || m.content
									}}</span>
									<ReactionButton :selected="m.reaction_id || 0" :reactions="readReactions" @change="(payload) => emitReaction(payload, m)"/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<simple-loading
					v-if="isScrolling"
					fontSize="text-2xl"
					class="text-xl red"
				></simple-loading>
			</div>
			<div v-else class="text-md m-auto font-md b blue px-5 text-center">
				<span v-if="selectedStatus == 'all' && selectedType == 'vote'">
					Aún no cuentas con reconocimientos
				</span>
				<span v-else-if="selectedStatus != 'all' && selectedType == 'vote'">
					No encontramos resultados para el filtro seleccionado
				</span>
				<span v-if="selectedStatus == 'all' && selectedType == 'reaction'">
					Aún no cuentas con reacciones
				</span>
				<span v-else-if="selectedStatus != 'all' && selectedType == 'reaction'">
					No encontramos resultados para el filtro seleccionado
				</span>
			</div>

			<Nav-bar-votacion :active="this.$route.name"></Nav-bar-votacion>
			<Footer class="static"></Footer>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.app-label-tag{
	background: #fff;
	border: 1px solid #d9291b;
	display: inline-block;
	border-radius: 2rem;
	padding: 0.25rem 1rem;
	margin: auto;
	font-weight: 700;
	font-size: 0.8rem;
	input[type="radio"] {
		display: none;
	}
	&--active{
		background-color: #d9291b;
		color: #fff;
	}
}
.radius-br-0{
	border-bottom-right-radius: 0px;
}
.radius-bl-0{
	border-bottom-left-radius: 0px;
}
.radius-tx-0 {
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
}
.animtae-smile{
	animation: smileIn .4s forwards ease-in-out;
	animation-delay: 0.4s;
	opacity: 0;
}

@keyframes smileIn{
  0%{
    opacity: 0;
    transform: scale(0.3) translate3d(0,0,0);
  }
  50%{
    opacity: 0.9;
    transform: scale(1.1);
  }
  80%{
    opacity: 1;
    transform: scale(0.89);
  }
  100%{
    opacity: 1;
    transform: scale(1) translate3d(0,0,0);
  }
}
.tab{
	padding: 1rem 0.5rem;
	padding-right: 24px;
	display: flex;
	justify-content: center;
	font-size: 0.7rem;
	position: relative;
	&:first-of-type{
		padding-left: 60px;
	}
	&--active{
		color: $red;
		box-shadow: inset 0 -4px $red;
	}
	&-indicator{
		position: absolute;
		right: 0.5rem;
		margin-left: auto;
		background-color: $red;
		color: #fff;
		width: 20px;
		height: 20px;
		line-height: 20px;
		font-size: 0.7rem;
		font-weight: 700;
		display: inline-block;
		border-radius: 20px;
	}
}
.overlay {
	height: calc(100% - 1rem);
}
.image {
	height: get-vh(860px);
	margin-bottom: -2rem;
}

.grayscale{
	filter: grayscale(100%);
}

.font-bold{
	font-weight: bold;
}

.message {
	border: get-vh(8px) solid #d6e2f0;
	user-select: none;
}

.item {
	box-shadow: 0px 8px 6px rgba($color: #224c57, $alpha: 0.25);

	&--unread {
		box-shadow: none;
		color: #fff;
		border: none;

		.icon_user {
			color: $red;
			background: $gray-light;
		}
	}
}

.icon_user {
	box-sizing: content-box;
	border: get-vh(6px) solid #d6e2f0;
	width: get-vh(120px);
	height: get-vh(120px);
	box-shadow: 0px 0px 5px 2px rgba($color: #d6e2f0, $alpha: 0.8);
	align-self: flex-start;
}

@keyframes go-in {
	0% {
		transform: translateX(100vw);
	}
	100% {
		transform: translateX(0vw);
	}
}

@keyframes go-out {
	0% {
		transform: translateX(0vw);
	}
	100% {
		transform: translateX(-100vw);
	}
}
.modal-base {
	opacity: 0;
	transform: translateX(100vw);
	background: none;
}
.modal-active {
	opacity: 1;
	animation: go-in 0.4s;
	transform: translateX(0vw);
}
.modal-out {
	opacity: 1;
	animation: go-out 0.4s;
	transform: translateX(-100vw);
}

.modal-wrap {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($color: #4a6c85, $alpha: 0.5);
	z-index: 10000000;
}
</style>
